import React, { Component } from "react";
import "../printStyle.css";

class PageTrmenats extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let array = [];

    this.props.state?.forEach((element, index) => {
      array.push(
        <tr key={index}>
          <td style={{ "font-size": "25px" }}>
            <div>{index + 1}</div>
          </td>
          <td style={{ "font-size": "25px" }}>
            <div>{element.drug.name}</div>
          </td>
          <td style={{ "font-size": "25px" }}>
            <div>{element.note}</div>
          </td>
        </tr>
      );
    });

    return (
      <div style={{ width: "29.7cm" }}>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th
                className="text-white p-0"
                style={{ width: "5%", "font-size": "25px" }}
              >
                <div className="bg-success m-0 p-3">#</div>
              </th>
              <th
                className="text-white p-0"
                style={{ width: "30%", "font-size": "25px" }}
              >
                <div className="bg-success m-0 p-3">Drug</div>
              </th>
              <th
                className="bg-success p-0"
                style={{ width: "65%", "font-size": "25px" }}
              >
                <div className="bg-success m-0 p-3">Note</div>
              </th>
            </tr>
          </thead>
          <tbody>{array}</tbody>
        </table>
      </div>
    );
  }
}

export default PageTrmenats;
