import swal from "sweetalert";

const showLoading = () => {
  swal({
    text: "Please wait...",
    closeOnClickOutside: false,
    buttons: false,
  });
};

export const showLoadingExport = () => {
  swal({
    text: "Please Wait",
    closeOnClickOutside: false,
    buttons: false,
  });
};
export default showLoading;
