import React, { useEffect, useRef, useState } from "react";
import "./styless.css";

import ChatTable from "./GrowthTable";
import { createOrUpdateGrwothChart } from "../create_visit_reducer";
import { zoomLevel } from "zoom-level";
import { useReactToPrint } from "react-to-print";

import PrintGrowthChart from "./PrintGrowthChart";

const ChatBarOne = (props) => {
  let chartType = props.chartType;
  let ex=[];
  const [zoom, setZoomLevel] = useState(1);
  const [state, setState] = useState([]);
   ex = props.state?.patient.growth_chart;
  let patient_id = props.state.patient?.id;
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [disable, setDisble] = useState(false);
  useEffect(() => {
        setZoomLevel(zoomLevel());
        if (ex?.length > 0) {
          let newData = [];
          [...ex].forEach((element) => {
            newData.push({
              y_axis: parseFloat(element.y_axis),
              x_axis: parseFloat(element.x_axis),
            });
          });
    
          setState([...newData]);
        } else {
          setState([]);
        }
      }, [props.state?.patient?.gender]);

  const onMouseMove = (event) => {
    setState([
      ...state,
      {
        // x_axis: event.nativeEvent.offsetX + 6,
        // y_axis: event.nativeEvent.offsetY - 2,
        x_axis: event.nativeEvent.offsetX + 13,
        y_axis: event.nativeEvent.offsetY + 41,
      },
    ]);
  };

  const removeDotes = (e) => {
    let datas = state;
    let afterFilter = datas?.filter(function (value) {
      return value.x_axis !== e.x_axis || value.y_axis !== e.y_axis;
    });
    setState([...afterFilter]);
  };

  const handeSubmit = async (e) => {
    e?.preventDefault();

    let x_axis = [];
    let y_axis = [];
    let id = [];
    let note = ["hello"];
    let type = [];

    
    state.forEach((key) => {
      
      x_axis.push(key?.x_axis);
      y_axis.push(key?.y_axis ?? "");
      note.push(key?.note ?? "");
      type.push(chartType);
    });

    console.log("old data : ",ex);
    if (ex !== null && ex !== undefined && ex.length > 0) {
      ex.forEach((element) => {
        if (chartType === element.type) {
          id.push(element.id);
        }
      });
    }

    setDisble(true);
    
    await createOrUpdateGrwothChart(
      // id.length > 0 ? "growth-charts/update" : "growth-charts/create",
      "growth-charts/update",
      {
        x_axis,
        y_axis,
        note,
        type,
        id,
        patient_id,
      }
    );

    setDisble(false);
  };
  window.addEventListener("resize", () => {
    setZoomLevel(zoomLevel());
  });

  return (
    <div className="">
      <div>
        <div>
          <img className="imgplotboy" onClick={onMouseMove} src={props.image} />
          {state.map((e) => {
            return (
              <div
                onClick={() => removeDotes(e)}
                className="dot"
                style={{
                  position: "absolute",
                  top: e.y_axis,
                  left: e.x_axis,
                  backgroundColor: "#bbb",
                  borderRadius: "50%",
                  display: "inline-block",
                  height: "4px",
                  width: "4px",
                  backgroundColor: "black",
                }}
              ></div>
            );
          })}
        </div>

        <div className="">
          <button
            disabled={disable}
            className="btn btn-success btn-lg mt-3 w-25"
            onClick={(e) => {
              handeSubmit();
            }}
          >
            Save
          </button>
          <div hidden>
            {" "}
            <PrintGrowthChart
              ref={componentRef}
              state={state}
              image={props.image}
            />
          </div>
          <button
            className="btn btn-primary w-75"
            onClick={() => {
              handlePrint();
            }}
          >
            Print
          </button>
        </div>
      </div>
      <br />
      <br />

      <br />
      <ChatTable state={props.state} chartType={`${chartType}`} />
    </div>
  );
};

export default ChatBarOne;
