import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { Form as FormBot } from "react-bootstrap";
import { saveExamination, updateExamination } from "./examination_operation";
import { useSelector } from "react-redux";

const ExaminationSection = (props) => {
  let isComplete = props.state.state == "completed";

  const [state, setState] = useState({});
  let ex = props.state?.examination;
  const [disable, setDisble] = useState(false);
  let wob = {};
  const allData = useSelector((state) => state.eachVisitDetalis);

  allData?.medical_history?.forEach((element) => {
    if (element.type == "weightOnBirth") {
      wob = element;
    }
  });
  useEffect(() => {
    let temp = {};
    if (ex.length > 0) {
      ex?.forEach((e) => {
        temp[e.type] = e.result;
      });

      setState({ ...temp });
    } else {
      setState({
        spo2: "",
        HR: "",
        Temperature: "",

        rbs: "",
        Hba1c: "",
        HC: "",
        wTail: "",
        hTail: "",
        HCtail: "",
        height: "",
        weight: "",
        BMI: "",
        valproicAcidSerumLevel: "",
        carbamazepineSerumLevel: "",
        other: "",
        headForm: "",
        dysmorphicSings: "",
        generalExamination: "",
        neurologicalExamination: "",
        behavior: "",
      });
    }
  }, []);

  const calculateBMI = (height, wight) => {
    let heightSquared = ((height / 100) * height) / 100;
    let bmi = round(wight / heightSquared, 1);

    return bmi;
  };
  const round = (value, precision) => {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  };
  const onSubmit = async (e) => {
    e.preventDefault();

    let types = [];
    let result = [];
    Object.entries(state).forEach(([key, value]) => {
      if (
        value.toString() != "" &&
        value.toString() != undefined &&
        value.toString() != null
      ) {
        types.push(key.toString());
        result.push(value.toString());
      }
    });

    let ids = [];
    setDisble(true);
    if (ex !== null && ex !== undefined && ex.length > 0) {
      let ids = [];
      ex.forEach((e) => {
        ids.push(e.id);
      });

      await updateExamination(types, result, props.state.id, ids);
      setDisble(false);
    } else {
      await saveExamination(types, result, props.state.id);
      setDisble(false);
    }
  };
  const userinfo = useSelector((state) => state.userLogin);
  const { userInfo } = userinfo;

  let isAdmni = userInfo.user_info.user_type == "admin";

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div>
          <div className="card">
            <div className="card-header bg-info">
              <h4 className="text-uppercase d-inline-block">Examination </h4>
            </div>

            {/**/}

            {/**/}
            <div className="card-body m-auto">
              {isAdmni == false ? null : (
                <div>
                  <div className="row">
                    <div className="form-group mr-2 ml-2">
                      <div className="mb-2">
                        <label htmlFor="spo2">spo2</label>
                        <input
                          disabled={isComplete}
                          placeholder="spo2"
                          type="number"
                          step="any"
                          value={state.spo2}
                          onChange={(e) => {
                            setState({ ...state, spo2: e.target.value });
                          }}
                          className={`form-control shadow-none  ml-1 mr-1`}
                        />
                      </div>

                      <label htmlFor="HR">HR</label>
                      <input
                        placeholder="HR"
                        disabled={isComplete}
                        type="number"
                        step="any"
                        value={state.HR}
                        onChange={(e) => {
                          setState({ ...state, HR: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>

                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="Temperature">Temperature</label>
                      <input
                        placeholder="Temperature"
                        type="number"
                        step="any"
                        disabled={isComplete}
                        value={state.Temperature}
                        onChange={(e) => {
                          setState({ ...state, Temperature: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group mr-2 ml-2"></div>
                  </div>

                  <div className="row">
                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="rbs">rbs</label>
                      <input
                        disabled={isComplete}
                        placeholder="rbs"
                        type="number"
                        step="any"
                        value={state.rbs}
                        onChange={(e) => {
                          setState({ ...state, rbs: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="Hba1c">Hba1c</label>
                      <input
                        disabled={isComplete}
                        placeholder="Hba1c"
                        type="number"
                        step="any"
                        value={state.Hba1c}
                        onChange={(e) => {
                          setState({ ...state, Hba1c: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                  </div>
                  {/* gggggggggggggg */}
                  <div className="row">
                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="Valproic acid Serum Level ">
                        Valproic acid Serum Level{" "}
                      </label>
                      <input
                        disabled={isComplete}
                        placeholder="Valproic acid Serum Level "
                        type="number"
                        step="any"
                        value={state.valproicAcidSerumLevel}
                        onChange={(e) => {
                          setState({
                            ...state,
                            valproicAcidSerumLevel: e.target.value,
                          });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="carbamazepineSerumLevel">
                        Carbamazepine Serum Level
                      </label>
                      <input
                        disabled={isComplete}
                        placeholder="Carbamazepine Serum Level"
                        type="number"
                        step="any"
                        value={state.carbamazepineSerumLevel}
                        onChange={(e) => {
                          setState({
                            ...state,
                            carbamazepineSerumLevel: e.target.value,
                          });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                  </div>

                  <hr />
                </div>
              )}

              {isAdmni == false ? null : (
                <div>
                  <div className="jumbotron pl-1 m-1 py-3">
                    <h4>Pediatric Examination</h4>
                    <hr />

                    <div className="row">
                      <div
                        className="form-group mr-2 ml-2 "
                        style={{ width: "120px" }}
                      >
                        <label htmlFor="Weigth On Birth">Weigth On Birth</label>
                        <input
                          placeholder="Weigth On Birth"
                          type="number"
                          step="any"
                          disabled
                          value={wob?.note}
                          className={`form-control shadow-none  ml-1 mr-1`}
                        />
                      </div>
                      <div
                        className="form-group mr-2 ml-2"
                        style={{ width: "120px" }}
                      >
                        <label htmlFor="height">height</label>
                        <input
                          disabled={isComplete}
                          placeholder="height"
                          type="number"
                          step="any"
                          value={state.height}
                          onChange={(e) => {
                            setState({
                              ...state,
                              height: e.target.value,
                              BMI: calculateBMI(
                                e.target.value,
                                state.weight
                              ).toFixed(1),
                            });
                          }}
                          className={`form-control shadow-none  ml-1 mr-1`}
                        />
                      </div>
                      <div
                        className="form-group mr-2 ml-2"
                        style={{ width: "120px" }}
                      >
                        <label htmlFor="weight">weight</label>
                        <input
                          placeholder="weight"
                          type="number"
                          step="any"
                          disabled={isComplete}
                          value={state.weight}
                          onChange={(e) => {
                            setState({
                              ...state,
                              weight: e.target.value,
                              BMI: calculateBMI(
                                state.height,
                                e.target.value
                              ).toFixed(1),
                            });
                          }}
                          className={`form-control shadow-none  ml-1 mr-1`}
                        />
                      </div>{" "}
                      <div className="form-group mr-2 ml-2">
                        <label htmlFor="HC">HC </label>
                        <input
                          placeholder="HC"
                          type="number"
                          step="any"
                          value={state.HC}
                          onChange={(e) => {
                            setState({ ...state, HC: e.target.value });
                          }}
                          className={`form-control shadow-none  ml-1 mr-1`}
                        />
                      </div>
                    </div>
                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="BMI">BMI </label>
                      <input
                        placeholder="BMI"
                        type="number"
                        step="any"
                        disabled
                        value={state.BMI}
                        onChange={(e) => {
                          setState({ ...state, BMI: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                    <div className="row">
                      <div className="form-group mr-2 ml-2">
                        <label htmlFor="Wtail">Wtail</label>
                        <input
                          disabled={isComplete}
                          placeholder="Wtail"
                          type="number"
                          step="any"
                          value={state.wTail}
                          onChange={(e) => {
                            setState({
                              ...state,
                              wTail: e.target.value,
                            });
                          }}
                          className={`form-control shadow-none  ml-1 mr-1`}
                        />
                      </div>
                      <div className="form-group mr-2 ml-2">
                        <label htmlFor="Htail">Htail</label>
                        <input
                          placeholder="Htail"
                          type="number"
                          step="any"
                          disabled={isComplete}
                          value={state.hTail}
                          onChange={(e) => {
                            setState({
                              ...state,
                              hTail: e.target.value,
                            });
                          }}
                          className={`form-control shadow-none  ml-1 mr-1`}
                        />
                      </div>
                      <div className="form-group mr-2 ml-2">
                        <label htmlFor="HCtail">HCtail </label>
                        <input
                          placeholder="HCtail"
                          type="number"
                          step="any"
                          value={state.HCtail}
                          onChange={(e) => {
                            setState({ ...state, HCtail: e.target.value });
                          }}
                          className={`form-control shadow-none  ml-1 mr-1`}
                        />
                      </div>
                    </div>
                  </div>
                  <hr />

                  <label htmlFor="generalExamination">
                    General Examination
                  </label>
                  <textarea
                    placeholder="General Examination"
                    disabled={isComplete}
                    value={state.generalExamination}
                    onChange={(e) => {
                      setState({
                        ...state,
                        generalExamination: e.target.value,
                      });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                  <label htmlFor="headForm">Head Form</label>
                  <textarea
                    placeholder="Head Form"
                    disabled={isComplete}
                    value={state.headForm}
                    onChange={(e) => {
                      setState({
                        ...state,
                        headForm: e.target.value,
                      });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                  <label htmlFor="dysmorphicSings">Dysmorphic Sings</label>
                  <textarea
                    placeholder="Dysmorphic Sings"
                    disabled={isComplete}
                    value={state.dysmorphicSings}
                    onChange={(e) => {
                      setState({
                        ...state,
                        dysmorphicSings: e.target.value,
                      });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                  <label htmlFor="neurologicalExamination">
                    Neurological Examination
                  </label>
                  <textarea
                    placeholder="Neurological Examination"
                    disabled={isComplete}
                    value={state.neurologicalExamination}
                    onChange={(e) => {
                      setState({
                        ...state,
                        neurologicalExamination: e.target.value,
                      });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                  <label htmlFor="behavior">behavior</label>
                  <textarea
                    placeholder="behavior"
                    disabled={isComplete}
                    value={state.behavior}
                    onChange={(e) => {
                      setState({
                        ...state,
                        behavior: e.target.value,
                      });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                  {/* /////// */}
                  <label htmlFor="other">other</label>
                  <textarea
                    placeholder="other"
                    disabled={isComplete}
                    value={state.other}
                    onChange={(e) => {
                      setState({ ...state, other: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </div>
              )}
              {/*stop from here examination*/}

              <button
                className="btn btn-success w-100 mt-4"
                type="submit"
                disabled={isComplete || disable}
              >
                Sumbit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default ExaminationSection;
