import { getToken } from "../../../../reducers/userReducers";
import api, { logout } from "../../../../constants/api";
import swal from "sweetalert";
import store from "../../../../store";
import { EDIT_SYSTEM_REVIEW, SET_SYSTEM_REVIEW } from "../create_visit_reducer";

export async function createSystemReviews(notes, types, visit_id) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.post(
      "system-reviews/create",
      {
        visit_id: visit_id,
        type: types,
        note: notes,
      },
      config
    );

    const data = response.data;
    store.dispatch({
      type: SET_SYSTEM_REVIEW,
      payload: {
        report: data,
        visit_id: visit_id,
      },
    });
    await swal("Good job!", "system reviews was saved successfully", "success");

    return true;
  } catch (error) {
    if (error.response?.status === 401) {
      logout();
    } else if (error.response?.status === 422) {
      await swal("Opps!", "The given data was invalid", "error");
      return false;
    } else {
      await swal("Opps!", "Failed to save system reviews ", "error");
    }
    return false;
  }
}

export async function upDateSystemReviews(notes, types, ids, visit_id) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.put(
      "system-reviews/update",
      {
        visit_id: visit_id,
        id: ids,
        type: types,
        note: notes,
      },
      config
    );

    const data = response.data;
    store.dispatch({
      type: SET_SYSTEM_REVIEW,
      payload: {
        report: data,
        visit_id: visit_id,
      },
    });
    console.log(data);
    await swal(
      "Good job!",
      "system reviews was updated successfully",
      "success"
    );

    return true;
  } catch (error) {
    if (error.response?.status === 401) {
      logout();
    } else if (error.response?.status === 422) {
      await swal("Opps!", "The given data was invalid", "error");
      return false;
    } else {
      await swal("Opps!", "Failed to updated system reviews", "error");
    }
    return false;
  }
}
