import React, { useEffect, useState, useRef } from "react";
import { updateOrCrateGrwothTable } from "../create_visit_reducer";
import { useReactToPrint } from "react-to-print";
import PrintGrowthTable from "./PrintGrowthTable";

const ChatTable = (props) => {
  const chartType = props.chartType;
  const [state, setState] = useState([]);
  const ex = props.state?.patient;
  const componentRefTable = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRefTable.current,
  });
  const patient_id = props.state.patient?.id;
  const [disable, setDisble] = useState(false);

  useEffect(() => {
    if (ex.length > 0) {
      setState([...ex]);
    } else {
      setState([]);
    }
  }, [props]);
  let list = [];

  state?.forEach((element, index) => {
    list.push(
      <tr key={element.id}>
        <td>
          <input
            value={element.date ?? null}
            type="date"
            onChange={(e) => {
              let data = state;
              data[index].date = e.target.value;
              setState([...data]);
            }}
            className="form-control ml-1 mr-1"
          />
        </td>{" "}
        <td>
          <input
            value={element.age ?? ""}
            onChange={(e) => {
              let data = state;
              data[index].age = e.target.value;
              setState([...data]);
            }}
            type="text"
            className="form-control ml-1 mr-1"
          />
        </td>{" "}
        <td>
          <input
            type="number"
            step={"0.001"}
            value={element.weight ?? ""}
            onChange={(e) => {
              let data = state;
              data[index].weight = e.target.value;
              setState([...data]);
            }}
            className="form-control ml-1 mr-1"
          />
        </td>{" "}
        <td>
          <input
            type="number"
            step={"0.001"}
            value={element.stature ?? ""}
            onChange={(e) => {
              let data = state;
              data[index].stature = e.target.value;
              setState([...data]);
            }}
            className="form-control ml-1 mr-1"
          />
        </td>
        <td>
          <input
            step={"0.001"}
            value={element.note ?? ""}
            onChange={(e) => {
              let data = state;
              data[index].note = e.target.value;
              setState([...data]);
            }}
            type="number"
            className="form-control ml-1 mr-1"
          />
        </td>{" "}
        <td>
          <input
            step={"0.001"}
            value={element.bmi ?? ""}
            onChange={(e) => {
              let data = state;
              data[index].bmi = e.target.value;
              setState([...data]);
            }}
            type="number"
            className="form-control ml-1 mr-1"
          />
        </td>{" "}
        <td>
          <button
            className="btn btn-danger"
            onClick={(event) => {
              let data = [...state];
              data.splice(index, 1);
              setState([...data]);
            }}
          >
            <i className="fas fa-trash  text-white"> </i>
          </button>
        </td>{" "}
      </tr>
    );
  });
  const handeSubmit = async (e) => {
    e?.preventDefault();
    let date = [];
    let age = [];
    let id = [];
    let height = [];
    let weight = [];
    let bmi = [];
    let stature = [];
    let note = [];
    let type = [];
    const checkForEmpty = (e) => {
      return (
        checkForEmptyOne(e.weight) &&
        checkForEmptyOne(e.data) &&
        checkForEmptyOne(e.bmi) &&
        checkForEmptyOne(e.stature)
      );
    };
    const checkForEmptyOne = (e) => {
      return e == "" || e == undefined;
    };

    state.forEach((key) => {
      if (!checkForEmpty(key)) {
        date.push(key?.date);
        note.push(key?.note ?? "");
        type.push(chartType);
        age.push(key?.age ?? "");
        weight.push(key?.weight ?? "");
        bmi.push(key?.bmi ?? "");
        height.push(0);
        stature.push(key?.stature ?? "");
      }
    });

    if (ex !== null && ex !== undefined && ex.length > 0) {
      ex.forEach((e) => {
        if (e.type === chartType) {
          id.push(e.id);
        }
      });
    }

    setDisble(true);

    await updateOrCrateGrwothTable(
      // id.length > 0 ? "growth-table/update" : "growth-table/create",
      "growth-table/update",
      {
        weight,
        bmi,
        stature,
        height: stature,
        date,
        age,
        type,
        id,
        note,
        patient_id,
      }
    );

    setDisble(false);
  };
  return (
    <section className="mt-2 rounded-lg">
      <table className="table table-bordered w-100  table-responsive">
        <thead className="bg-info">
          <tr>
            <th className="w-20">Date</th>
            <th className="w-20">Age</th>
            <th className="w-20">stature</th>
            <th className="w-20">weight</th>
            <th className="w-20">HC</th>
            <th className="w-20">bmi</th>
            <th className="w-20">delete</th>
          </tr>
        </thead>
        <tbody>{list}</tbody>
      </table>
      <button
        className="btn btn-warning btn-lg mt-3"
        onClick={(e) => {
          setState([
            ...state,
            {
              date: "",
              age: "",
              stature: "",
              weight: "",
              height: "",
              note: "",
              bmi: "",
            },
          ]);
        }}
      >
        Add Row
      </button>
      <div hidden>
        <PrintGrowthTable
          ref={componentRefTable}
          state={state}
          patient={props.state?.patient}
        />
      </div>
      <div className="w-100 col">
        <button
          disabled={disable}
          onClick={(e) => {
            handeSubmit();
          }}
          className="btn btn-success btn-lg mt-3 w-70"
        >
          Save
        </button>{" "}
        <button
          className="btn btn-primary btn-lg mt-3 w-25"
          onClick={(event) => {
            handlePrint();
          }}
        >
          Print
        </button>
      </div>
    </section>
  );
};

export default ChatTable;
