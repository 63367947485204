import swal from "sweetalert";
import api, { logout } from "../../constants/api";
import { getToken } from "../../reducers/userReducers";
import store from "../../store";
import showLoadingExport from "../alert/show_alert";
import exportFromJSON from "export-from-json";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";

export default function exportReducer(
  state = {
    isLoaded: false,
    data: {
      total: 0,
      exported: 0,
    },
  },
  action
) {
  switch (action.type) {
    case RESET_EXPORT:
      return (state = {
        data: {
          total: 0,
          exported: 0,
        },
      });
    case SET_EXPORT: {
      return {
        total: action.total,
        exported: action.exported,
      };
    }

    default:
      return state;
  }
}
let page = 0;
let take = 50;
let data = [];

export async function getDataFirstFunction(
  with_visits,
  relations,
  fileName,
  medical_history,
  treatment,
  systemReview,
  currentLlenuss,
  cvs,
  visit_images,
  report,
  invistigation,
  examination
) {
  console.log({
    with_visits,
    relations,
    fileName,
    medical_history,
    treatment,
    systemReview,
    currentLlenuss,
    cvs,
    visit_images,
    report,
    invistigation,
    examination,
  });
  page = 0;

  data = [];
  startGetData(
    with_visits,
    relations,
    fileName,
    medical_history,
    treatment,
    systemReview,
    currentLlenuss,
    cvs,
    visit_images,
    report,
    invistigation,
    examination
  );
}

export async function startGetData(
  with_visits,
  relations,
  fileName,
  medical_history,
  treatment,
  systemReview,
  currentLlenuss,
  cvs,
  visit_images,
  report,
  invistigation,
  examination
) {
  if (page == 0) {
    showLoadingExport();
  }
  let newData = await getAllVisitsData(with_visits, relations, page, take);

  data = data.concat(newData.patients);
  if (newData.total <= page * take) {
    parseData(
      data,
      fileName,
      with_visits,
      medical_history,
      treatment,
      systemReview,
      currentLlenuss,
      cvs,
      visit_images,
      report,
      invistigation,
      examination
    );
  } else {
    page++;
    startGetData(
      with_visits,
      relations,
      fileName,
      medical_history,
      treatment,
      systemReview,
      currentLlenuss,
      cvs,
      visit_images,
      report,
      invistigation,
      examination
    );
  }
}
export async function getAllVisitsData(with_visits, relations, page, take) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };

  store.dispatch({ type: RESET_EXPORT, payload: false });
  try {
    const response = await api.get(
      `clinic-reports/get-patient-info?with_visits=${with_visits}&relations=${relations}&_page=${
        page * take
      }&_take=${take}`,
      config
    );

    if (
      document.getElementsByClassName("swal-text")[0] != null &&
      document.getElementsByClassName("swal-text")[0]
    ) {
      document.getElementsByClassName("swal-text")[0].innerHTML =
        page * take + " from " + response.data.total;
    }
    const data = response.data;
    return data;
  } catch (error) {
    console.log(error);
    await swal("Opps!", "Failed to export patient data", "error");

    if (error.response?.status === 401) {
      logout();
    }
  }
}
async function parseData(
  rawData,
  fileNameParam,
  with_visits,
  medical_history,
  treatmentIncilde,
  systemReview,
  currentLlenuss,
  cvs,
  visit_images,
  report,
  invistigation,
  examination
) {
  try {
    let data = [];
    rawData.forEach((personElement) => {
      let currentData = {};
      currentData = {
        id: personElement.id,
        code: personElement.code,
        fullname: personElement.fullname ?? "",
        phone: personElement.phone ?? "",
        birthdate: personElement.birthdate ?? "",
        gender: personElement.gender ?? "",
        blood_type: personElement.blood_type ?? "",
        address: personElement.info?.address ?? "",
        marital_status: personElement.info?.marital_status ?? "",
        alkohol: personElement.info?.alkohol ?? "",
        smoking: personElement.info?.smoking ?? "",
        doctor: personElement.info?.doctor?.name ?? "",
        occupation: personElement.info?.occupation?.name ?? "",
      };
      if (cvs == true) {
        let images = "";
        personElement.cvs.forEach((image) => {
          images = images + (image.url ?? "");
        });
        currentData["cvs"] = images;
      }
      if (medical_history == true) {
        personElement.medical_history.forEach((element) => {
          currentData[element.disease + " Type"] = element.note;
          currentData[element.disease + " Range"] = element.range;
        });
      }
      data.push(currentData);

      if (with_visits == true) {
        personElement.all_visits.forEach((visit) => {
          currentData = {};
          currentData["state"] = visit.state;
          currentData["visit_id"] = visit.id;
          currentData["price"] = visit.price;
          currentData["created_at"] = visit.created_at;

          if (currentLlenuss == true) {
            visit?.currentLlenuss.forEach((currentLlenus) => {
              currentData[currentLlenus.type] = currentLlenus.note;
            });
          }

          if (systemReview) {
            visit?.systemReview.forEach((systemReviews) => {
              currentData[systemReviews.type] = systemReviews.note;
            });
          }
          if (examination) {
            visit?.examination.forEach((examinationVal) => {
              console.log(examinationVal);
              currentData[examinationVal.type] = examinationVal.result;
            });
          }
          if (treatmentIncilde == true) {
            let treatment = "";
            visit?.treatment.forEach((treatments) => {
              treatment =
                treatment +
                (treatments.drug?.name ?? "") +
                " " +
                (treatment.note ?? "");
            });
            currentData["treatment"] = treatment;
          }
          if (visit_images == true) {
            let images = "";
            visit?.images.forEach((image) => {
              images = images + (image.url ?? "");
            });
            currentData["visit_images"] = images;
          }
          if (invistigation == true) {
            let invistigations = "";
            visit?.invistigation.forEach((temp) => {
              invistigations =
                invistigations + (temp.test?.name + `(${temp.result_number})-`);
            });
            currentData["invistigation"] = invistigations;
          }

          if (
            report == true &&
            visit.dischargReport?.note != "" &&
            visit.dischargReport?.note != null &&
            visit.dischargReport?.note != undefined
          ) {
            console.log(visit.dischargReport?.note);
            const content = EditorState.createWithContent(
              convertFromRaw(JSON.parse(JSON.parse(visit.dischargReport?.note)))
            );

            let toStr = content.getCurrentContent().getPlainText();
            console.log("content ", toStr);

            currentData["report"] = toStr ?? "";
          }

          data.push(currentData);
        });
      }
    });
    const fileName = fileNameParam;
    const exportType = exportFromJSON.types.csv;

    exportFromJSON({ data, fileName, exportType, withBOM: true });

    swal("Good job!", "patient data Was exported successfully", "success");
  } catch (error) {
    console.log(error);
    await swal("Opps!", "Failed to export patient data", "error");
  }
}

export const SET_EXPORT = "SET_EXPORT";

export const RESET_EXPORT = "RESET_EXPORT";
