import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { getOccupationAction } from "../../../actions/occupationAction";
import { getRefDoctor } from "../../../actions/referalDoctor";
import { deleteCVS, editPatinet } from "../../../actions/pationactions";
import Select from "react-select";
import swal from "sweetalert";
import { GetFormattedDate } from "../../../constants/labConstants";

const ProfileVisit = (props) => {
  const patent = { ...props.state.patient };

  const dispatch = useDispatch();
  const [disable, setDisble] = useState(false);

  useEffect(() => {
    getAge(patent.birthdate);
  }, []);
  const [state, setState] = useState({
    doctor_id: {
      value: patent.info.doctor?.id,
      label: patent.info.doctor?.name,
    },

    alkohol: patent.info.alkohol.toString(),
    smoking: patent.info.smoking.toString(),
    fullname: patent.fullname,
    blood_type: patent.blood_type,
    gender: patent.gender,
    phone: patent.phone,
    cvs: [],
    address: patent.info.address,
    birthdate: new Date(patent.birthdate),
    marital_status: patent.info.marital_status,
    occupation: {
      value: patent.info.occupation?.id,
      label: patent.info.occupation?.name,
    },
  });

  const [age, setAge] = useState("");
  const [fileKey, setFileKey] = useState(new Date());

  function getAge(dateString) {
    var nowDate = new Date(new Date().setHours(0, 0, 0, 0));
    // Example date of birth.
    var dobDate = new Date(dateString);

    var years = nowDate.getFullYear() - dobDate.getFullYear();
    var months = nowDate.getMonth() - dobDate.getMonth();
    var days = nowDate.getDate() - dobDate.getDate();

    // Work out the difference in months.
    months += years * 12;
    if (days < 0) {
      months -= 1;
    }
    // Now add those months to the date of birth.
    dobDate.setMonth(dobDate.getMonth() + months);
    // Calculate the difference in milliseconds.
    let diff = nowDate - dobDate;
    // Divide that by 86400 to get the number of days.
    var days = Math.round(diff / 86400 / 1000);
    // Now convert months back to years and months.
    years = parseInt(months / 12);
    months -= years * 12;

    let text = "";
    if (years) {
      text = years + (years > 1 ? " years" : " year");
    }
    if (months) {
      if (text.length) {
        text = text + ", ";
      }
      text = text + months + (months > 1 ? " months" : " month");
    }
    if (days) {
      if (text.length) {
        text = text + ", ";
      }
      text = text + days + (days > 1 ? " days" : " day");
    }
    if (nowDate === dobDate) {
      text = "Newborn";
    }
    if (
      text === "" ||
      text === null ||
      text === undefined ||
      days < 0 ||
      months < 0 ||
      years < 0
    ) {
      text = "age can not be calculated";
    }
    setAge(text);
  }

  const [error, setError] = useState({
    fullname: "",
    phone: "",
    age: "",
  });
  const getOccoupation = useSelector((state) => state.getOccupation);
  const userlogin = useSelector((state) => state.userLogin);
  const referalDoctor = useSelector((state) => state.getDoctor.referalDoctor);
  const { userInfo } = userlogin;
  const { getOccupations } = getOccoupation;

  useEffect(() => {
    dispatch(getOccupationAction(userInfo.token));
    dispatch(getRefDoctor(userInfo.token));
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();

    if (validate() === false) {
      return;
    }
    setDisble(true);
    await editPatinet(
      state.fullname,
      state.phone,
      state.birthdate.toISOString().split("T")[0],
      state.gender,
      state.blood_type,
      state.address,
      state.occupation.value ?? "",
      state.doctor_id.value ?? "",
      state.marital_status,
      state.alkohol,
      state.smoking,

      GetFormattedDate(new Date()),
      state.cvs,
      patent.id,
      reset
    );
    setDisble(false);
  };
  const reset = () => {
    setFileList([]);
    setState({ ...state, cvs: [] });
    setFileKey(new Date());
  };

  const validate = () => {
    let tempError = error;
    if (state.fullname === "") {
      tempError = { ...tempError, fullname: "required" };
    } else if (state.fullname.length < 4) {
      tempError = { ...tempError, fullname: "short name" };
    } else {
      tempError = { ...tempError, fullname: "" };
    }
    if (state.phone === "") {
      tempError = { ...tempError, phone: "required" };
    } else if (state.phone.length !== 11) {
      tempError = {
        ...tempError,
        phone: "phone must be 11 digit",
      };
    } else {
      tempError = { ...tempError, phone: "" };
    }

    if (
      state.doctor_id === "" ||
      state.doctor_id === null ||
      state.doctor_id.length === 0
    ) {
      tempError = {
        ...tempError,
        doctor_id: "required",
      };
    } else {
      tempError = { ...tempError, doctor_id: "" };
    }

    if (tempError.fullname === "" && tempError.phone === "") {
      return true;
    } else {
      setError(tempError);
      return false;
    }
  };

  let occupationOptions = [];

  getOccupations?.forEach((occupation) => {
    occupationOptions.push({ value: occupation.id, label: occupation.name });
  });

  let doctor_idOptions = [];
  if (referalDoctor.length !== 0) {
    referalDoctor?.forEach((doctor_id) => {
      doctor_idOptions.push({ value: doctor_id.id, label: doctor_id.name });
    });
  }
  const [fileList, setFileList] = useState([]);

  let files = [];
  let oldImages = [];
  props.state.patient.cvs.forEach((e, index) => {
    oldImages.push(
      <tr key={Math.floor(Math.random() * 435611)}>
        <td>
          <a href={e.url} target="_blank">
            <img width="100" height="100" id="target" src={e.url} />
          </a>
        </td>
        <td className="text-center">
          <button
            className="btn btn-danger"
            onClick={(eveent) => {
              swal({
                title: "Are you sure?",
                text: "Once deleted, you will not be able to recover this image",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  deleteCVS(e.id);
                } else {
                }
              });
            }}
          >
            <i className="fas fa-trash"> </i>
          </button>
        </td>
      </tr>
    );
  });
  fileList.forEach((e, index) => {
    files.push(
      <tr key={Math.floor(Math.random() * 435611)}>
        <td>
          <img width="100" height="100" id="target" src={e.image} />
        </td>
        <td className="text-center">
          <button
            className="btn btn-danger"
            onClick={(t) => {
              let temp = [];
              for (let i = 0; i < fileList?.length; i++) {
                if (i !== index) {
                  temp.push(fileList[i]);
                }
              }
              let temp2 = [];
              for (let i = 0; i < state.cvs.length; i++) {
                if (i !== index) {
                  temp2.push(state.cvs[i]);
                }
              }
              console.log("[...temp][...temp]", [...temp]);
              setFileList([...temp]);
              setState({ ...state, cvs: [...temp2] });
            }}
          >
            <i className="fas fa-trash"> </i>
          </button>
        </td>
      </tr>
    );
  });
  console.log("[...temp][...temp]", state);

  return (
    <div className="card">
      <div className="card-header bg-info">Edit Patient</div>
      <div className="card-body">
        <div className="text-center border border-light p-5">
          <div className="form-row mb-3">
            <div className="col mr-3 text-left">
              <label htmlFor="fullname">Full name</label>
              <br />
              <input
                type="text"
                id="fullname"
                className="form-control"
                placeholder="Full name"
                defaultValue={state.fullname}
                onChange={(e) => {
                  setState({ ...state, fullname: e.target.value });
                  if (e.target.value === "" || e.target.value === undefined) {
                    setError({ ...error, fullname: "required" });
                  } else if (e.target.value.length < 4) {
                    setError({ ...error, fullname: "short full name" });
                  } else {
                    setError({ ...error, fullname: "" });
                  }
                }}
              />
              <div style={{ fontSize: 12, color: "red" }}>{error.fullname}</div>
            </div>
            <div className="col ml-3 text-left">
              <label htmlFor="phone">Phone number</label>
              <br />
              <input
                type="text"
                id="phone"
                className="form-control"
                placeholder="Phone number"
                value={state.phone}
                onChange={(e) => {
                  setState({ ...state, phone: e.target.value });
                  if (e.target.value === "" || e.target.value === undefined) {
                    setError({ ...error, phone: "required" });
                  } else if (e.target.value.length !== 11) {
                    setError({ ...error, phone: "wrong phone number" });
                  } else {
                    setError({ ...error, phone: "" });
                  }
                }}
              />
              <div style={{ fontSize: 12, color: "red" }}>{error.phone}</div>
            </div>
          </div>
          {/*end first Row*/}
          <div className="form-row mb-3 mt-4">
            <div className="col mr-3 text-left">
              <label className="col-form-label mr-3">Birthday</label>

              <DatePicker
                className="form-control"
                selected={state.birthdate}
                onChange={(date) => {
                  if (date === null) {
                    return;
                  }
                  getAge(date.toISOString().split(" ")[0]);

                  setState({
                    ...state,
                    birthdate: date,
                    age: new Date().getFullYear() - date.getFullYear(),
                  });
                }}
              />
            </div>
            <div className="col ml-3">
              <input
                id="AgeWithNumber"
                className="form-control"
                placeholder="Age "
                disabled={true}
                value={age}
              />
              <div style={{ fontSize: 12, color: "red" }}>{error.age}</div>
            </div>
          </div>
          {/*second row*/}
          <div className="form-row mb-3">
            <div className="col mr-3 text-left">
              <label className="col-form-label">Gender</label>

              <select
                value={state.gender}
                onChange={(e) => {
                  setState({ ...state, gender: e.target.value });
                }}
                className="browser-default custom-select form-control"
                placeholder="Gender"
              >
                <option value="Male" selected>
                  Male
                </option>
                <option value="Female">Female</option>
                <option value="Intersex">Intersex</option>
              </select>
            </div>
            <div className="col ml-3 text-left">
              <label className="col-form-label">Marital Status</label>

              <select
                value={state.marital_status}
                onChange={(e) => {
                  setState({ ...state, marital_status: e.target.value });
                }}
                className="browser-default custom-select form-control"
                placeholder="Marital Status"
              >
                <option value="Single">Single</option>
                <option value="Married">Married</option>
                <option value="Divorced">Divorced</option>
              </select>
            </div>
          </div>

          {/*third row*/}
          <div className="form-row mb-3">
            <div className="col mr-3 text-left">
              <label className="col-form-label">Parent Occupation</label>
              <div className="select2-purple">
                <Select
                  id="inputSupplier"
                  // className="basic-single form-control border-info"
                  classNamePrefix="select test"
                  name="form-scientific_name_id-name"
                  isDisabled={false}
                  isClearable={false}
                  isSearchable={true}
                  style={{ borderRadius: "6px" }}
                  value={state.occupation}
                  onChange={(e) => {
                    setState({ ...state, occupation: e });
                  }}
                  options={occupationOptions}
                />
              </div>
            </div>
            <div className="col ml-3 text-left">
              <label className="col-form-label">Referral Doctor</label>
              <Select
                id="inputSupplier"
                // className="basic-single form-control border-info"
                classNamePrefix="select refreal doctor"
                name="form-scientific_name_id-name"
                isDisabled={false}
                isClearable={false}
                value={state.doctor_id}
                isSearchable={true}
                onChange={(e) => {
                  setState({ ...state, doctor_id: e });
                }}
                options={doctor_idOptions}
              />
            </div>
          </div>
          {/*four row*/}
          <div className="form-row mb-3">
            <div className="col mr-3 text-left">
              <label className="col-form-label">Address</label>

              <input
                type="text"
                id="addressInput"
                className="form-control"
                placeholder="address "
                value={state.address}
                onChange={(e) => {
                  setState({ ...state, address: e.target.value });
                }}
              />
              <div style={{ fontSize: 12, color: "red" }}>{error.address}</div>
            </div>
            <div className="col ml-5 text-left">
              <label className="col-form-label">blood type</label>

              <select
                value={state.blood_type}
                onChange={(e) => {
                  setState({ ...state, blood_type: e.target.value });
                }}
                className="browser-default custom-select form-control"
                placeholder="blood ype"
              >
                <option value="A+">A+</option>
                <option value="A-">A-</option>
                <option value="B+">B+</option>
                <option value="B-">B-</option>
                <option value="O+">O+</option>
                <option value="O-">O-</option>
                <option value="AB+">AB+</option>
                <option value="AB-"> AB-</option>
              </select>
            </div>
          </div>
          {/*five row*/}

          <button
            type="submit"
            disabled={disable}
            className="btn btn-info w-50"
            onClick={submitHandler}
          >
            <span>Submit</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileVisit;
