import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import {
  saveMedicalHistory,
  updateMedicalHistory,
} from "../EachVisition/create_visit_reducer";

const HestoryVisit = (props) => {
  const sizeYes = {
    width: "20%",
    minWidth: "100px",
  };
  const sizeDrug = {
    width: "100%",
  };
  const [disable, setDisble] = useState(false);

  const [state, setState] = useState();
  let ex = props.state;
  let patient_id = props.patient_id;

  useEffect(() => {
    let temp = {};
    if (ex.length > 0) {
      ex?.forEach((e) => {
        temp[e.type + "Range"] = e.range;
        temp[e.disease] = e.note;
      });
      setState({ ...temp });
    } else {
      setState({
        surgicalHistory: "",
        familyHistory: "",
        Hospitalization: "",
        Hypertension: "",

        weightOnBirth: "",
        typeOfBirth: "5",
        other: "",
        vaccination: "",
        historyOfChronicDiseases: "",
        bloodTransfusion: "",
        //////
        feedingHistory: "",
        speechHistory: "",
        speechDevelopmentHistory: "",
        grossMotorFunction: "",
        fineMotorFunction: "",
        medicalAids: "",
        sleepHistory: "",
        urinationAndDefecationHistory: "",
        ///////////
        surgicalHistoryRange: "0",
        familyHistoryRange: "0",
        HospitalizationRange: "0",
        HypertensionRange: "0",
        typeOfBirthRange: "natural",
        weightOnBirthRange: "0",
        otherRange: "0",
        vaccinationRange: "yes",
        bloodTransfusionRange: "0",
        historyOfChronicDiseasesRange: "0",

        feedingHistoryRange: "0",
        speechHistoryRange: "0",
        speechDevelopmentHistoryRange: "0",
        grossMotorFunctionRange: "0",
        fineMotorFunctionRange: "0",
        medicalAidsRange: "0",
        sleepHistoryRange: "0",
        urinationAndDefecationHistoryRange: "0",
      });
    }
  }, []);

  ////////////

  //

  const handeSubmit = async (e) => {
    e.preventDefault();

    let types = [];
    let ranges = [];
    let notes = [];
    let diseases = [];
    Object.entries(state).forEach(([key, value]) => {
      if (key.toString().toLowerCase().includes("range")) {
        ranges.push(value?.toString());
      } else {
        types.push(key?.toString());
        notes.push(value?.toString());
      }
    });

    let ids = [];
    setDisble(true);
    if (ex !== null && ex !== undefined && ex.length > 0) {
      let ids = [];
      ex.forEach((e) => {
        ids.push(e.id);
      });

      await updateMedicalHistory(types, ranges, notes, patient_id, ids);
      setDisble(false);
    } else {
      await saveMedicalHistory(types, ranges, notes, patient_id);
      setDisble(false);
    }
  };

  return (
    <div>
      <form onSubmit={handeSubmit}>
        <section>
          <div className="input-group input-group-lg">
            <div className="w-100">
              <label htmlFor="surgicalHistory">Surgical History</label>
              <textarea
                placeholder="surgicalHistory"
                value={state?.surgicalHistory}
                onChange={(e) => {
                  setState({ ...state, surgicalHistory: e.target.value });
                }}
                className={`form-control shadow-none  ml-1 mr-1`}
              />
            </div>
          </div>
          <div className="input-group input-group-lg mt-2">
            <div className="w-100">
              <label htmlFor="familyHistory">Family History</label>
              <textarea
                placeholder="familyHistory"
                value={state?.familyHistory}
                onChange={(e) => {
                  setState({ ...state, familyHistory: e.target.value });
                }}
                className={`form-control shadow-none  ml-1 mr-1`}
              />
            </div>
          </div>
          <div className="input-group input-group-lg mt-2">
            <div className="w-100">
              <label htmlFor="Hospitalization">Hospitalization</label>
              <textarea
                placeholder="Hospitalization"
                value={state?.Hospitalization}
                onChange={(e) => {
                  setState({ ...state, Hospitalization: e.target.value });
                }}
                className={`form-control shadow-none  ml-1 mr-1`}
              />
            </div>
          </div>
          <div className="input-group input-group-lg mt-2">
            <div className="w-100">
              <label htmlFor="bloodTransfusion">blood transfusion</label>
              <textarea
                placeholder="blood transfusion"
                value={state?.bloodTransfusion}
                onChange={(e) => {
                  setState({ ...state, bloodTransfusion: e.target.value });
                }}
                className={`form-control shadow-none  ml-1 mr-1`}
              />
            </div>
          </div>{" "}
          <div className="input-group input-group-lg mt-2">
            <div className="w-100">
              <label htmlFor="Feeding history ">Feeding history </label>
              <textarea
                placeholder="Feeding history "
                value={state?.feedingHistory}
                onChange={(e) => {
                  setState({ ...state, feedingHistory: e.target.value });
                }}
                className={`form-control shadow-none  ml-1 mr-1`}
              />
            </div>
          </div>
          <div className="input-group input-group-lg mt-2">
            <div className="w-100">
              <label htmlFor="Speech history">Speech history</label>
              <textarea
                placeholder="Speech history"
                value={state?.speechHistory}
                onChange={(e) => {
                  setState({ ...state, speechHistory: e.target.value });
                }}
                className={`form-control shadow-none  ml-1 mr-1`}
              />
            </div>
          </div>
          <div className="input-group input-group-lg mt-2">
            <div className="w-100">
              <label htmlFor="Speech development history ">
                Speech development history{" "}
              </label>
              <textarea
                placeholder="Speech development history "
                value={state?.speechDevelopmentHistory}
                onChange={(e) => {
                  setState({
                    ...state,
                    speechDevelopmentHistory: e.target.value,
                  });
                }}
                className={`form-control shadow-none  ml-1 mr-1`}
              />
            </div>
          </div>{" "}
          <div className="input-group input-group-lg mt-2">
            <div className="w-100">
              <label htmlFor="Gross motor function">Gross motor function</label>
              <textarea
                placeholder="Gross motor function"
                value={state?.grossMotorFunction}
                onChange={(e) => {
                  setState({
                    ...state,
                    grossMotorFunction: e.target.value,
                  });
                }}
                className={`form-control shadow-none  ml-1 mr-1`}
              />
            </div>
          </div>
          <div className="input-group input-group-lg mt-2">
            <div className="w-100">
              <label htmlFor="Fine motor function">Fine motor function</label>
              <textarea
                placeholder="Fine motor function"
                value={state?.fineMotorFunction}
                onChange={(e) => {
                  setState({
                    ...state,
                    fineMotorFunction: e.target.value,
                  });
                }}
                className={`form-control shadow-none  ml-1 mr-1`}
              />
            </div>
          </div>{" "}
          <div className="input-group input-group-lg mt-2">
            <div className="w-100">
              <label htmlFor="medical aids">medical aids</label>
              <textarea
                placeholder="medical aids"
                value={state?.medicalAids}
                onChange={(e) => {
                  setState({
                    ...state,
                    medicalAids: e.target.value,
                  });
                }}
                className={`form-control shadow-none  ml-1 mr-1`}
              />
            </div>
          </div>
          <div className="input-group input-group-lg mt-2">
            <div className="w-100">
              <label htmlFor="Sleep history ">Sleep history </label>
              <textarea
                placeholder="Sleep history "
                value={state?.sleepHistory}
                onChange={(e) => {
                  setState({
                    ...state,
                    sleepHistory: e.target.value,
                  });
                }}
                className={`form-control shadow-none  ml-1 mr-1`}
              />
            </div>
          </div>
          <div className="input-group input-group-lg mt-2">
            <div className="w-100">
              <label htmlFor="Urination and defecation history">
                Urination and defecation history
              </label>
              <textarea
                placeholder="Urination and defecation history"
                value={state?.urinationAndDefecationHistory}
                onChange={(e) => {
                  setState({
                    ...state,
                    urinationAndDefecationHistory: e.target.value,
                  });
                }}
                className={`form-control shadow-none  ml-1 mr-1`}
              />
            </div>
          </div>
        </section>
        {/*top history*/}
        <hr />
        <section className="mt-2 rounded-lg">
          <div className="table table-bordered w-100  table-responsive p-3">
            <div>
              <div className="input-group input-group-lg mt-2">
                <div className="w-100">
                  <label htmlFor="history of chronic diseases">
                    history of chronic diseases
                  </label>
                  <textarea
                    placeholder="history of chronic diseases"
                    value={state?.historyOfChronicDiseases}
                    onChange={(e) => {
                      setState({
                        ...state,
                        historyOfChronicDiseases: e.target.value,
                      });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </div>
              </div>
              <br />

              <h3 className="table-bordered w-100 center p-3">
                Pediatric history
              </h3>

              {/*15*/}

              <div className="row w-100">
                <div className="form-group  w-100">
                  <label htmlFor="weight on birth">weight on birth</label>
                  <input
                    type="number"
                    step="0.001"
                    placeholder="weight on birth"
                    value={state?.weightOnBirth}
                    onChange={(e) => {
                      setState({ ...state, weightOnBirth: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </div>
                <div className="w-100 form-group ">
                  <label htmlFor="Type of birth">Type of birth</label>
                  <Form.Control
                    as="select"
                    value={state?.typeOfBirthRange}
                    onChange={(e) => {
                      setState({
                        ...state,
                        typeOfBirthRange: e.target.value,
                      });
                    }}
                  >
                    <option>natural</option>
                    <option>surgery</option>
                  </Form.Control>
                </div>
              </div>

              <div className="">
                <div className="w-100">
                  <label htmlFor="Vaccination">Vaccination</label>
                  <Form.Control
                    as="select"
                    value={state?.vaccinationRange}
                    onChange={(e) => {
                      setState({
                        ...state,
                        vaccinationRange: e.target.value,
                      });
                    }}
                  >
                    <option>Yes</option>
                    <option>No</option>
                    <option>Some</option>
                  </Form.Control>
                  <br />
                  <textarea
                    placeholder="vaccination"
                    value={state?.vaccination}
                    onChange={(e) => {
                      setState({ ...state, vaccination: e.target.value });
                    }}
                    className={`form-control shadow-none  `}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*table history*/}
        <section className="mt-2">
          <div className="mt-2">
            {/*bithday wight*/}

            <hr />
            <div className="input-group input-group-lg mt-2">
              <div className="w-100">
                <label htmlFor="other">other</label>
                <textarea
                  placeholder="other"
                  value={state?.other}
                  onChange={(e) => {
                    setState({ ...state, other: e.target.value });
                  }}
                  className={`form-control shadow-none  ml-1 mr-1`}
                />
              </div>
            </div>
          </div>
        </section>

        <button
          className="btn btn-success btn-lg mt-3 w-100"
          type="submit"
          disabled={disable}
        >
          Submit
        </button>
      </form>{" "}
    </div>
  );
};

export default HestoryVisit;
