import React, { Component } from "react";
import { getAge } from "../../../../print/printTremnats/Invistigation_print";
import header from "../../../../print/images/header.png";
import footer from "../../../../print/images/footer.png";
import "./styless.css";

class PrintGrowthTable extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let list = [];
    const invoice = this.props.state;
    let state = this.props.state;
    let patient = this.props.patient;
    state?.forEach((element, index) => {
      list.push(
        <tr key={element.id}>
          <td style={{ width: "14%" }}>
            <span style={{ "font-size": "35px" }}>{element.date ?? null}</span>
          </td>
          <td style={{ width: "14%" }}>
            <span style={{ "font-size": "35px" }}>{element.age ?? null}</span>
          </td>
          <td style={{ width: "14%" }}>
            <span style={{ "font-size": "35px" }}>
              {element.weight ?? null}
            </span>
          </td>
          <td style={{ width: "14%" }}>
            <span style={{ "font-size": "35px" }}>
              {element.stature ?? null}
            </span>
          </td>
          <td style={{ width: "14%" }}>
            <span style={{ "font-size": "35px" }}>{element.note ?? null}</span>
          </td>{" "}
          <td style={{ width: "14%" }}>
            <span style={{ "font-size": "35px" }}>{element.bmi ?? null}</span>
          </td>
        </tr>
      );
    });
    // const invoice = this.props.state;
    console.log("invoice", invoice);
    return (
      <div>
        <table>
          <thead>
            <tr>
              <td>
                <div>
                  <img src={header} />
                  <div className="bg-primary border rounded p-2 d-flex justify-content-between">
                    <h6 className="text-bold" style={{ "font-size": "35px" }}>
                      Name: {patient.fullname}
                    </h6>
                    <h6 className="text-bold" style={{ "font-size": "35px" }}>
                      Gender: {patient.gender}
                    </h6>
                    <h6 className="text-bold" style={{ "font-size": "35px" }}>
                      Age: {getAge(patient.birthdate)}
                    </h6>
                  </div>
                </div>
              </td>
            </tr>
          </thead>
        </table>
        <tbody>
          <table className="table table-bordered" style={{ width: "43cm" }}>
            <thead>
              <tr>
                <th className="p-0" style={{ width: "14%" }}>
                  <div
                    className="bg-success m-0 p-3 text-white"
                    style={{ "font-size": "35px" }}
                  >
                    Date
                  </div>
                </th>
                <th className="p-0" style={{ width: "14%" }}>
                  <div
                    className="bg-success m-0 p-3 text-white"
                    style={{ "font-size": "35px" }}
                  >
                    Age
                  </div>
                </th>
                <th className="p-0" style={{ width: "14%" }}>
                  <div
                    className="bg-success m-0 p-3 text-white"
                    style={{ "font-size": "35px" }}
                  >
                    stature
                  </div>
                </th>
                <th className="p-0" style={{ width: "14%" }}>
                  <div
                    className="bg-success m-0 p-3 text-white"
                    style={{ "font-size": "35px" }}
                  >
                    weight
                  </div>
                </th>
                <th className="p-0" style={{ width: "14%" }}>
                  <div
                    className="bg-success m-0 p-3 text-white"
                    style={{ "font-size": "35px" }}
                  >
                    HC
                  </div>
                </th>
                <th className="p-0" style={{ width: "14%" }}>
                  <div
                    className="bg-success m-0 p-3 text-white"
                    style={{ "font-size": "35px" }}
                  >
                    BMI
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>{list}</tbody>
          </table>
        </tbody>

        <tfoot>
          <tr>
            <td>
              <div
                style={{
                  height: "100px",
                  bottom: "20px",
                  position: "fixed",

                  padding: "5px",
                }}
              >
                <img src={footer} />
              </div>
            </td>
          </tr>
        </tfoot>
      </div>
    );
  }
}

export default PrintGrowthTable;
