import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GetFormattedDate } from "../../../../constants/labConstants";
import { createIllense, upDateIllences } from "./history_current_illenes";

const HistoryOfCurrentIlliness = (props) => {
  let isComplete = props.state.state == "completed";
  let notes = {
    chiefComplain: "",
    appointment: "",
    followUp: "",
    diagnosis: "",
  };
  props.state?.currentLlenuss?.forEach((e) => {
    notes[e.type] = e.note;
  });
  notes["appointment"] = new Date();

  const [state, setState] = useState(notes);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (props.state?.currentLlenuss.length === 0) {
    } else {
    }

    let data = [];
    Object.entries(state).forEach(([key, value]) => {
      data.push({ type: key, note: value });
    });
    let note = [];
    let types = [];
    data.forEach((e) => {
      types.push(e.type);
      note.push(e.note);
    });

    if (state["appointment"] != null && state["appointment"] != undefined) {
      note["appointment"] = GetFormattedDate(state["appointment"]);
    } else {
      note["appointment"] = state["appointment"]?.toISOString().split("T")[0];
    }
    setDisble(true);
    if (props.state?.currentLlenuss?.length > 0) {
      let ids = [];

      props.state?.currentLlenuss.forEach((er) => {
        ids.push(er.id);
      });
      await upDateIllences(note, types, ids, props.state.id);
      setDisble(false);
    } else {
      await createIllense(note, types, props.state.id);
      setDisble(false);
    }
  };
  const [disable, setDisble] = useState(false);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="card card-outline">
          <div className="card-header bg-info">
            <h4 className="text-uppercase d-inline-block">
              History of current Illness
            </h4>{" "}
            <h4 className="d-inline-block ml-2">--Images :</h4>
            <i className="fas fa-image text-red ml-2"> </i>
          </div>
          <div className="card-body">
            <div className="form-group input-group-lg">
              <label> Chief Complain</label>
              <textarea
                className="form-control d-inline"
                aria-label="With textarea"
                disabled={isComplete}
                defaultValue={state?.chiefComplain}
                onChange={(e) => {
                  setState({ ...state, chiefComplain: e.target.value });
                }}
              />
            </div>
            <div className="form-group input-group-lg mt-2">
              <label> Plan & follow up</label>
              <textarea
                disabled={isComplete}
                className="form-control d-inline"
                aria-label="With textarea"
                defaultValue={state?.followUp}
                onChange={(e) => {
                  setState({ ...state, followUp: e.target.value });
                }}
              />
            </div>
            <div className="form-group input-group-lg mt-2">
              <label>
                {" "}
                Differential diagnosis <br />& diagnosis
              </label>
              <textarea
                disabled={isComplete}
                className="form-control d-inline"
                aria-label="With textarea"
                defaultValue={state?.diagnosis}
                onChange={(e) => {
                  setState({ ...state, diagnosis: e.target.value });
                }}
              />
            </div>

            {/*<div className="mt-3">*/}
            {/*  <label className="col-form-label mr-3">*/}
            {/*    {" "}*/}
            {/*    Next Visitation Appointment Date*/}
            {/*  </label>*/}

            {/*  <DatePicker*/}
            {/*    className="form-control"*/}
            {/*    selected={state.appointment}*/}
            {/*    disabled={isComplete}*/}
            {/*    onChange={(date) => {*/}
            {/*      setState({ ...state, appointment: date });*/}
            {/*    }}*/}
            {/*  />*/}

            {/*  <i*/}
            {/*    className="far fa-calendar-times  ml-2 text-red"*/}
            {/*    style={{ fontSize: "1.2rem" }}*/}
            {/*  >*/}
            {/*    {" "}*/}
            {/*  </i>*/}
            {/*</div>*/}
            <button
              type="submit"
              disabled={isComplete || disable}
              className="btn btn-success w-100 mt-4"
            >
              Sumbit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default HistoryOfCurrentIlliness;
