import React, { useState } from "react";
//Female
import F0_36WH from "./F0-36WH.png";
import F0_36WC from "./F0-36WC.png";
import F2_20WH from "./F2-20WH.png";
import F2_20WC from "./F2-20WC.png";
// Male
import M0_36WH from "./M0-36WH.png";
import M0_36WC from "./M0-36WC.png";
import M2_20WH from "./M2-20WH.png";
import M2_20WC from "./M2-20WC.png";

import { useSelector } from "react-redux";
import ChatBarOne from "./ChatBarOne";
import "bootstrap/dist/css/bootstrap.css";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
const ChatBarTabs = () => {
  const statesss = useSelector((state) => state.eachVisitDetalis);
  console.log("statesss", statesss);
  const isMale = statesss?.patient?.patient?.gender === "Male";
  console.log("gendergendergender", isMale, statesss?.patient?.patient?.gender);

  return (
    <div>
      <Tabs defaultActiveKey="zeroToThreeMonthWC">
        <Tab eventKey="zeroToThreeMonthWC" title="0-33 Months W.C">
          <ChatBarOne
            image={isMale ? M0_36WC : F0_36WC}
            state={statesss.patient}
            chartType="zeroToThreeMonthWC"
            key="zeroToThreeMonthWC"
          />
        </Tab>
        <Tab eventKey="zeroToThreeMonthWH" title="0-33 Months W.H">
          <ChatBarOne
            image={isMale ? M0_36WH : F0_36WH}
            state={statesss.patient}
            chartType="zeroToThreeMonthWH"
            key="zeroToThreeMonthWH"
          />
        </Tab>
        <Tab eventKey="twoToTwentyTowYearWC" title="2-22 Year W.C">
          <ChatBarOne
            image={isMale ? M2_20WC : F2_20WC}
            state={statesss.patient}
            chartType="twoToTwentyTowYearWC"
            key="twoToTwentyTowYearWC"
          />
        </Tab>
        <Tab eventKey="twoToTwentyTowYearWH" title="2-22 Year W.H">
          <ChatBarOne
            image={isMale ? M2_20WH : F2_20WH}
            state={statesss.patient}
            chartType="twoToTwentyTowYearWH"
            key="twoToTwentyTowYearWH"
          />
        </Tab>
      </Tabs>
    </div>
  );
};

export default ChatBarTabs;
