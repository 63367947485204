import React, { Component } from "react";
import "./styless.css";

class PrintGrowthChart extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const invoice = this.props.state;
    console.log("invoice", invoice);
    return (
      <div>
        <img style={{ width: "30cm" }} src={this.props.image} />
        {invoice?.map((e) => {
          return (
            <div
              className="dot"
              style={{
                position: "absolute",
                top: e.y_axis - 41,
                left: e.x_axis - 13,
                // top: e.y_axis + 41 * 8.6,
                // left: e.x_axis + 13 * 12,
                backgroundColor: "#bbb",
                borderRadius: "50%",
                display: "inline-block",
                height: "4px",
                width: "4px",
                backgroundColor: "black",
              }}
            ></div>
          );
        })}
      </div>
    );
  }
}

export default PrintGrowthChart;
